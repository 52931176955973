//
//
//
//

import queryString from 'query-string'

export default {
    props: {
        videoId: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        embedUrl: function () {
            const params = queryString.stringify(this.options)

            if (params) {
                return `https://www.youtube-nocookie.com/embed/${this.videoId}?playlist=${this.videoId}` + (params ? `&${params}` : '')
            } else {
                return `https://www.youtube-nocookie.com/embed/${this.videoId}`
            }
        }
    }
}
