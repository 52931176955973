//
//
//
//

/* eslint-disable no-labels */
const markdown = require('markdown-it')({
    html: true
})

export default {
    props: {
        source: {
            type: String
        },
        size: {
            type: String,
            default: 'text-body'
        },
        weight: {
            type: String,
            default: 'font-light'
        }
    },
    computed: {
        html: function () {
            return this.source ? markdown.render(this.source) : ''
        },
        classes: function () {
            return `${this.size} ${this.weight}`
        }
    },
    mounted () {
        embedsocial: {
            try {
                const widgets = this.$refs.markdown.querySelectorAll(':scope .custom-embedsocial')
                if (widgets.length === 0) { break embedsocial }

                const script = document.getElementById('EmbedSocialHashtagScript')
                if (script === null) { break embedsocial }

                if (typeof standardLoad === 'function') {
                    standardLoad(widgets) /* eslint-disable-line no-undef */
                } else {
                    script.addEventListener('load', function () {
                        standardLoad(widgets) /* eslint-disable-line no-undef */
                    })
                }
            } catch (_) {
            }
        }
    }
}
